/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { ensureOneOfType as e } from "./core/accessorSupport/ensureType.js";
import o from "./geometry/Extent.js";
import t from "./geometry/Geometry.js";
import r from "./geometry/Multipoint.js";
import p from "./geometry/Point.js";
import m from "./geometry/Polygon.js";
import i from "./geometry/Polyline.js";
export { default as SpatialReference } from "./geometry/SpatialReference.js";
export { featureGeometryTypeKebabDictionary, typeKebabDictionary } from "./geometry/support/typeUtils.js";
export { fromJSON } from "./geometry/support/jsonUtils.js";
function y(e) {
  return e instanceof t;
}
const n = {
    base: t,
    key: "type",
    typeMap: {
      extent: o,
      multipoint: r,
      point: p,
      polyline: i,
      polygon: m
    }
  },
  s = e(n);
export { t as BaseGeometry, o as Extent, r as Multipoint, p as Point, m as Polygon, i as Polyline, s as ensureType, n as geometryTypes, y as isGeometry };